import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionProcesses from "../components/Section/SectionProcesses"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const ProcessPage = () => (
  <Layout>
    <Seo title="Cadastre um processo" />
    <SectionProcesses />
    <WppButtonFloat />
  </Layout>
)

export default ProcessPage
