import styled from "styled-components"

import { breakpoints, sizes } from "../../styles/breakpoints"

export const AreasOfExpertise = styled.div`
  margin-top: 25px;

  .card-wrapper {
    margin: 7.5px 0;
  }
`

export const Card = styled.article`
  position: relative;
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  max-height: 260px;
  transition: all ease 0.2s;
  @media ${breakpoints.lessThan(sizes.desktop)} {
    max-height: 100%;
  }

  .c-header {
    display: flex;
    align-items: center;

    .img {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }

    .c-title {
      font-size: 1.2rem;
      font-weight: bold;
      color: var(--color-primary);
    }
  }

  .c-content {
    margin-top: 15px;

    .description {
      color: var(--color-black);
      font-size: 1rem;
      line-height: 1.4;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .c-link.-full {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover {
    background-color: var(--color-primary);

    .c-header {
      .img {
        filter: invert(65%) sepia(54%) saturate(471%) hue-rotate(2deg)
          brightness(91%) contrast(88%);
      }

      .c-title {
        color: var(--color-white);
      }
    }

    .c-content {
      .description {
        color: var(--color-grayscale3);
      }
    }
  }

  &:active {
    transform: scale(0.98);
  }

  &.-only-image {
    padding: 10px;
    height: auto;
    max-height: unset;
    @media ${breakpoints.lessThan(sizes.desktop)} {
      max-height: unset;
    }

    .img {
      height: 280px;
      @media ${breakpoints.lessThan(sizes.phone)} {
        height: 230px;
      }
      @media ${breakpoints.lessThan(sizes.smaller)} {
        height: 170px;
      }
    }

    &:hover {
      max-height: unset;
    }
  }
`
