import React, { useState } from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { IMaskInput } from "react-imask"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Col } from "react-flexbox-grid"

import * as S from "./styled"
import { Button } from "../../Button"
import { Title, Paragraph } from "../../Text"
import Select from "../../Select"
import AreasOfExpertise from "../../AreasOfExpertise"

import { FileEarmarkArrowUpFill as FileIcon } from "@styled-icons/bootstrap/FileEarmarkArrowUpFill"

const SweetAlert = withReactContent(Swal)

const FormProcessIndication = () => {
  const [formStepState, setFormStepState] = useState(1)
  const [selectedProcessArea, setSelectedProcessArea] = useState(
    "previdenciario"
  )
  const [indicationUserId] = useQueryParam("indicationUserId", StringParam)
  const [indicationName] = useQueryParam("indicationName", StringParam)
  const [indicationEmail] = useQueryParam("indicationEmail", StringParam)
  const [indicationPhone] = useQueryParam("indicationPhone", StringParam)

  const fileInputRG = React.createRef()
  const fileInputCPF = React.createRef()
  const fileInputCTPS = React.createRef()
  const fileInputProofOfAddress = React.createRef()
  const fileInputCNIS = React.createRef()
  const fileInputMedicalReport = React.createRef()
  const fileInputINSSRejection = React.createRef()
  const fileInputOthersDocuments = React.createRef()
  const fileInputTerminationTerm = React.createRef()

  const formProcessInitialValues = {
    indicationUserId: indicationUserId || "",
    indicationName: indicationName || "",
    indicationEmail: indicationEmail || "",
    indicationPhone: indicationPhone || "",
    indicationDate: new Date().toJSON().slice(0, 10),
    processUserName: "",
    processUserEmail: "",
    processUserPhone: "",
    processUserBirthday: "",
    processUserCPF: "",
    processUserAddress: "",
    processTitle: "Processo",
    processStatus: "Enviado pelo cliente",
    processAreasOfExpertise: {
      selectedOption: { value: "", label: "Selecione" },
      options: [
        {
          value: "previdenciario",
          label: (
            <div className="select-option-custom">
              <StaticImage
                className="img"
                src="../../images/icons/balance.svg"
                objectFit="contain"
                objectPosition="center"
                placeholder="tracedSVG"
                alt="Direito previdenciário"
              />
              <div className="box-text">
                <h6 className="option-title">Direito previdenciário</h6>
                <p className="description">
                  - Previdência Social <br />
                  - Direito Empresarial Previdenciário <br />
                  - Previdência Complementar <br />
                  - Regimes Próprio de Previdência Social (RPPS) <br />
                  - Planejamento previdenciário <br />- Princípio da
                  contributividade e da universalidade
                </p>
              </div>
            </div>
          ),
        },
        {
          value: "civil",
          label: (
            <div className="select-option-custom">
              <StaticImage
                className="img"
                src="../../images/icons/balance.svg"
                objectFit="contain"
                objectPosition="center"
                placeholder="tracedSVG"
                alt="Direito civil"
              />
              <div className="box-text">
                <h6 className="option-title">Direito civil</h6>
                <p className="description">
                  - Direito da Família <br />
                  - Direito das Obrigações
                  <br />
                  - Direito Contratual
                  <br />
                  - Direito das Sucessões
                  <br />
                  - Direito das Empresas
                  <br />- Direito das Coisas
                </p>
              </div>
            </div>
          ),
        },
        {
          value: "trabalho",
          label: (
            <div className="select-option-custom">
              <StaticImage
                className="img"
                src="../../images/icons/balance.svg"
                objectFit="contain"
                objectPosition="center"
                placeholder="tracedSVG"
                alt="Direito do trabalho"
              />
              <div className="box-text">
                <h6 className="option-title">Direito do trabalho</h6>
                <p className="description">
                  Lidamos com as questões que envolvem as relações entre patrões
                  e empregados. prestamos consultoria, na prevenção de conflitos
                </p>
              </div>
            </div>
          ),
        },
        {
          value: "consumidor",
          label: (
            <div className="select-option-custom">
              <StaticImage
                className="img"
                src="../../images/icons/balance.svg"
                objectFit="contain"
                objectPosition="center"
                placeholder="tracedSVG"
                alt="Direito do consumidor"
              />
              <div className="box-text">
                <h6 className="option-title">Direito do consumidor</h6>
                <p className="description">
                  Atuamos diante das relações de consumo, em defesa do
                  consumidor ou do fornecedor,
                </p>
              </div>
            </div>
          ),
        },
        {
          value: "empresarial",
          label: (
            <div className="select-option-custom">
              <StaticImage
                className="img"
                src="../../images/icons/balance.svg"
                objectFit="contain"
                objectPosition="center"
                placeholder="tracedSVG"
                alt="Direito Empresarial"
              />
              <div className="box-text">
                <h6 className="option-title">Direito Empresarial</h6>
                <p className="description">
                  Prestamos assessoria jurídica para empresas e empresários,
                  afim de orientar a encontrar soluções mais adequadas, para
                  possam ampliar sua participação no mercado, prevenir perdas
                  financeiras e melhorar a eficiência e lucratividade da
                  atividade empresarial. Além de mediar conflitos envolvendo os
                  interesses empresários ou relacionados à empresa.
                </p>
              </div>
            </div>
          ),
        },
        {
          value: "tributario",
          label: (
            <div className="select-option-custom">
              <StaticImage
                className="img"
                src="../../images/icons/balance.svg"
                objectFit="contain"
                objectPosition="center"
                placeholder="tracedSVG"
                alt="Direito Tributario"
              />
              <div className="box-text">
                <h6 className="option-title">Direito Tributario</h6>
                <p className="description">
                  Contamos com especialistas em tributação, para proporcionar as
                  melhores soluções em recuperação de créditos tributários,
                  planejamento tributário, consultoria tributária, gestão do
                  passivo tributário, Compliance Tributário. Ainda auxiliamos
                  nos pagamentos corretos dos tributos, para proporciona melhor
                  rentabilidade com menor custo para as empresas.
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
    processDescription: "",
    processUserRGUploadFile: "",
    processUserCPFUploadFile: "",
    processUserCTPSUploadFile: "",
    processUserProofOfAddressUploadFile: "",
    processUserCNISUploadFile: "",
    processUserMedicalReportUploadFile: "",
    processUserINSSRejectionUploadFile: "",
    processUserOthersDocumentsUploadFile: "",
    processUserTerminationTermUploadFile: "",
  }

  const formProcessValidationSchema = Yup.object().shape({
    indicationName: Yup.string().notRequired(),
    indicationEmail: Yup.string().notRequired(),
    indicationPhone: Yup.string()
      .min(10, "Digite um telefone válido (com DDD)")
      .max(11, "Digite um telefone válido (com DDD)")
      .required("Campo obrigatório"),
    processUserName: Yup.string().required("Campo obrigatório"),
    processUserEmail: Yup.string().notRequired(),
    processUserPhone: Yup.string()
      .min(10, "Digite um telefone válido (com DDD)")
      .max(11, "Digite um telefone válido (com DDD)")
      .required("Campo obrigatório"),
    processUserBirthday: Yup.string(),
    processUserCPF: Yup.string()
      .matches(
        /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/,
        "CPF Inválido"
      )
      .min(11, "CPF inválido")
      .max(11, "CPF inválido"),
    processUserAddress: Yup.string().notRequired(),
    processTitle: Yup.string().notRequired(),
    processStatus: Yup.string().notRequired(),
    processAreasOfExpertise: Yup.object().shape({
      selectedOption: Yup.object().shape({
        value: Yup.string().required("Selecione uma opção"),
      }),
    }),
    processDescription: Yup.string().required("Campo obrigatório"),
    processUserRGUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserCPFUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserCTPSUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserProofOfAddressUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserCNISUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserMedicalReportUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserINSSRejectionUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserOthersDocumentsUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
    processUserTerminationTermUploadFile: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "is-file-correct-type",
        "Selecione um arquivo PDF, JPG, JPEG ou PNG.",
        value =>
          !value ||
          ["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(
            value.type
          )
      )
      .test(
        "is-file-too-big",
        "Selecione um arquivo de até 1MB",
        value => !value || value.size <= 1000000
      ),
  })

  const uploadDocument = async (file, id) => {
    if (file && id) {
      const formDataFiles = new FormData()

      formDataFiles.append("files", file, file.name)
      formDataFiles.append("refId", id)
      formDataFiles.append("ref", "indications")
      formDataFiles.append("field", "processDocuments")

      // eslint-disable-next-line no-unused-vars
      const responseDataFiles = await axios({
        method: "post",
        url: "https://ruy-molina-back.herokuapp.com/upload",
        data: formDataFiles,
      })

      // console.log(responseDataFiles)
    }
  }

  const formProcessOnSubmit = async (values, { setFieldError, resetForm }) => {
    // console.log("OnSubmit values: ", values)

    var dia = values.processUserBirthday.split("/")[0]
    var mes = values.processUserBirthday.split("/")[1]
    var ano = values.processUserBirthday.split("/")[2]

    // Destructuring submit data (remove files properties)
    const {
      processUserRGUploadFile,
      processUserCPFUploadFile,
      processUserCTPSUploadFile,
      processUserProofOfAddressUploadFile,
      processUserCNISUploadFile,
      processUserMedicalReportUploadFile,
      processUserINSSRejectionUploadFile,
      processUserOthersDocumentsUploadFile,
      processUserTerminationTermUploadFile,
      ...formSubmitData
    } = values

    const data = {
      ...formSubmitData,
      processTitle: `[${values.processAreasOfExpertise.selectedOption.value.toUpperCase()}] - ${
        values.processUserName
      }`,
      processAreasOfExpertise:
        values.processAreasOfExpertise.selectedOption.value,
      processUserBirthday:
        ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2),
    }

    try {
      // eslint-disable-next-line no-unused-vars
      const responseData = await axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "https://ruy-molina-back.herokuapp.com/indications",
        data: data,
      })

      await uploadDocument(processUserRGUploadFile, responseData.data.id)
      await uploadDocument(processUserCPFUploadFile, responseData.data.id)
      await uploadDocument(processUserCTPSUploadFile, responseData.data.id)
      await uploadDocument(
        processUserProofOfAddressUploadFile,
        responseData.data.id
      )
      await uploadDocument(processUserCNISUploadFile, responseData.data.id)
      await uploadDocument(
        processUserMedicalReportUploadFile,
        responseData.data.id
      )
      await uploadDocument(
        processUserINSSRejectionUploadFile,
        responseData.data.id
      )
      await uploadDocument(
        processUserOthersDocumentsUploadFile,
        responseData.data.id
      )
      await uploadDocument(
        processUserTerminationTermUploadFile,
        responseData.data.id
      )

      SweetAlert.fire({
        title: "Sucesso!",
        text:
          "Seu processo foi encaminhado para análise. Aguarde nosso contato em até 48 horas",
        icon: "success",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 3500,
      })

      // console.log(responseData)

      resetForm()
    } catch (error) {
      console.log(error.response)
      switch (error.response.status) {
        case 500:
          SweetAlert.fire({
            title: "Oops...",
            text: "Tente novamente mais tarde!",
            icon: "error",
            showCloseButton: false,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
          })
          break

        default:
          const errors = error.response.data.data.errors

          SweetAlert.fire({
            title: "Oops...",
            text: "Verifique os erros e tente novamente!",
            icon: "error",
            showCloseButton: false,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
          })

          Object.keys(errors).map(fieldError =>
            setFieldError(fieldError, errors[fieldError])
          )
          break
      }
    }
  }

  return (
    <Formik
      validationSchema={formProcessValidationSchema}
      onSubmit={formProcessOnSubmit}
      initialValues={formProcessInitialValues}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <S.Form autoComplete="off" onSubmit={handleSubmit}>
          {formStepState === 1 ? (
            <fieldset className="fieldset">
              <Title className="title" as="h2">
                Selecione a área de atuação do processo:
              </Title>
              <AreasOfExpertise
                values={values}
                setFormStepState={setFormStepState}
                setSelectedProcessArea={setSelectedProcessArea}
              />
            </fieldset>
          ) : (
            <Row>
              <Col lg={9}>
                <fieldset className="fieldset">
                  <Title className="title" as="h2">
                    Dados do indicador
                  </Title>
                  <Paragraph className="paragraph">
                    Caso esteja indicando um processo para algum
                    conhecido/familiar ou outra pessoa, informe os seus dados
                    para que possamos lhe agradecer da devida forma.
                  </Paragraph>

                  <Row>
                    <Col md={5}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_indicationName">
                          Nome
                        </label>
                        <input
                          className={`form-control ${
                            touched.indicationName && errors.indicationName
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_indicationName"
                          name="indicationName"
                          placeholder="Nome e sobrenome"
                          type="text"
                          value={values.indicationName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {touched.indicationName && errors.indicationName && (
                          <span className="error-message">
                            {errors.indicationName}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_indicationEmail">
                          Email
                        </label>
                        <input
                          className={`form-control ${
                            touched.indicationEmail && errors.indicationEmail
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_indicationEmail"
                          name="indicationEmail"
                          placeholder="exemplo@email.com"
                          type="email"
                          value={values.indicationEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.indicationEmail && errors.indicationEmail && (
                          <span className="error-message">
                            {errors.indicationEmail}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_indicationPhone">
                          Telefone *
                        </label>
                        <IMaskInput
                          className={`form-control ${
                            touched.indicationPhone && errors.indicationPhone
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_indicationPhone"
                          name="indicationPhone"
                          placeholder="(88) 988888888"
                          type="tel"
                          value={values.indicationPhone}
                          mask="(00) 000000000"
                          unmask={true}
                          onAccept={value => {
                            values.indicationPhone = value
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.indicationPhone && errors.indicationPhone && (
                          <span className="error-message">
                            {errors.indicationPhone}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </fieldset>

                <fieldset className="fieldset">
                  <Title className="title" as="h2">
                    Dados pessoais
                  </Title>
                  <Paragraph className="paragraph">
                    Dados pessoais da pessoa que está sendo indicada na qual o
                    processo pode ser aberto. Certifique-se de preencher as
                    informações corretamente.
                  </Paragraph>

                  <Row>
                    <Col md={5}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_processUserName">
                          Nome *
                        </label>
                        <input
                          className={`form-control ${
                            touched.processUserName && errors.processUserName
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_processUserName"
                          name="processUserName"
                          placeholder="Nome e sobrenome"
                          type="text"
                          value={values.processUserName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {touched.processUserName && errors.processUserName && (
                          <span className="error-message">
                            {errors.processUserName}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_processUserEmail">
                          Email
                        </label>
                        <input
                          className={`form-control ${
                            touched.processUserEmail && errors.processUserEmail
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_processUserEmail"
                          name="processUserEmail"
                          placeholder="exemplo@email.com"
                          type="email"
                          value={values.processUserEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.processUserEmail &&
                          errors.processUserEmail && (
                            <span className="error-message">
                              {errors.processUserEmail}
                            </span>
                          )}
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_processUserPhone">
                          Telefone *
                        </label>
                        <IMaskInput
                          className={`form-control ${
                            touched.processUserPhone && errors.processUserPhone
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_processUserPhone"
                          name="processUserPhone"
                          placeholder="(88) 988888888"
                          type="tel"
                          value={values.processUserPhone}
                          mask="(00) 000000000"
                          unmask={true}
                          onAccept={value => {
                            values.processUserPhone = value
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.processUserPhone &&
                          errors.processUserPhone && (
                            <span className="error-message">
                              {errors.processUserPhone}
                            </span>
                          )}
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="form-group">
                        <label
                          className="label"
                          htmlFor="id_processUserBirthday"
                        >
                          Data de nascimento
                        </label>
                        <IMaskInput
                          className={`form-control ${
                            touched.processUserBirthday &&
                            errors.processUserBirthday
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_processUserBirthday"
                          name="processUserBirthday"
                          placeholder="00/00/0000"
                          type="text"
                          value={values.processUserBirthday}
                          mask="00/00/0000"
                          unmask={false}
                          onAccept={value => {
                            values.processUserBirthday = value
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.processUserBirthday &&
                          errors.processUserBirthday && (
                            <span className="error-message">
                              {errors.processUserBirthday}
                            </span>
                          )}
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_processUserCPF">
                          CPF
                        </label>
                        <IMaskInput
                          className={`form-control ${
                            touched.processUserCPF && errors.processUserCPF
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_processUserCPF"
                          name="processUserCPF"
                          placeholder="000.000.000-00"
                          type="text"
                          value={values.processUserCPF}
                          mask="000.000.000.00"
                          unmask={true}
                          onAccept={value => {
                            values.processUserCPF = value
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.processUserCPF && errors.processUserCPF && (
                          <span className="error-message">
                            {errors.processUserCPF}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={5}>
                      <div className="form-group">
                        <label className="label" htmlFor="id_processUserName">
                          Endereço
                        </label>
                        <input
                          className={`form-control ${
                            touched.processUserAddress &&
                            errors.processUserAddress
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_processUserAddress"
                          name="processUserAddress"
                          placeholder="Endereço completo"
                          type="text"
                          value={values.processUserAddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        {touched.processUserAddress &&
                          errors.processUserAddress && (
                            <span className="error-message">
                              {errors.processUserAddress}
                            </span>
                          )}
                      </div>
                    </Col>
                  </Row>
                </fieldset>

                <fieldset className="fieldset">
                  <Title className="title" as="h3">
                    Dados do processo
                  </Title>

                  <Row>
                    <Col md={7}>
                      <div className="form-group">
                        <label
                          className="label"
                          htmlFor="id_processAreasOfExpertise"
                        >
                          Área de atuação
                        </label>
                        <Select
                          inputId="id_processAreasOfExpertise"
                          name="processAreasOfExpertise"
                          className={`react-select-container ${
                            touched.processAreasOfExpertise &&
                            errors.processAreasOfExpertise
                              ? "-is-invalid"
                              : ""
                          }`}
                          classNamePrefix="react-select"
                          isSearchable={false}
                          value={values.processAreasOfExpertise.selectedOption}
                          onChange={e => {
                            values.processAreasOfExpertise.selectedOption.value =
                              e.value
                            values.processAreasOfExpertise.selectedOption.label =
                              e.label
                            setSelectedProcessArea(e.value)
                          }}
                          onBlur={handleBlur}
                          options={values.processAreasOfExpertise.options}
                        />
                        {touched.processAreasOfExpertise &&
                          errors.processAreasOfExpertise && (
                            <span className="error-message">
                              {
                                errors.processAreasOfExpertise.selectedOption
                                  .value
                              }
                            </span>
                          )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className="form-group">
                        <label
                          className="label"
                          htmlFor="id_processDescription"
                        >
                          Descrição da ação *
                        </label>
                        <textarea
                          className={`form-control -textarea ${
                            touched.processDescription &&
                            errors.processDescription
                              ? "-is-invalid"
                              : ""
                          }`}
                          id="id_processDescription"
                          name="processDescription"
                          placeholder="Conte-nos um pouco da sua história..."
                          rows="10"
                          value={values.processDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></textarea>
                        {touched.processDescription &&
                          errors.processDescription && (
                            <span className="error-message">
                              {errors.processDescription}
                            </span>
                          )}
                      </div>
                    </Col>
                  </Row>
                </fieldset>

                <fieldset className="fieldset">
                  <Title className="title" as="h3">
                    Upload de documentos
                  </Title>

                  <Row>
                    {/* DOCS PREVIDENCIÁRIO */}
                    {selectedProcessArea === "previdenciario" ? (
                      <>
                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserRGUploadFile"
                            >
                              RG
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserRGUploadFile &&
                                errors.processUserRGUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserRGUploadFile"
                                  id="processUserRGUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserRGUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputRG}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputRG.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserRGUploadFile
                                    ? values.processUserRGUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserRGUploadFile &&
                              errors.processUserRGUploadFile && (
                                <span className="error-message">
                                  {errors.processUserRGUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserCPFUploadFile"
                            >
                              CPF
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserCPFUploadFile &&
                                errors.processUserCPFUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserCPFUploadFile"
                                  id="processUserCPFUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserCPFUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputCPF}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputCPF.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserCPFUploadFile
                                    ? values.processUserCPFUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserCPFUploadFile &&
                              errors.processUserCPFUploadFile && (
                                <span className="error-message">
                                  {errors.processUserCPFUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserCTPSUploadFile"
                            >
                              CTPS (Carteira de Trabalho)
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserCTPSUploadFile &&
                                errors.processUserCTPSUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserCTPSUploadFile"
                                  id="processUserCTPSUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserCTPSUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputCTPS}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputCTPS.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserCTPSUploadFile
                                    ? values.processUserCTPSUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserCTPSUploadFile &&
                              errors.processUserCTPSUploadFile && (
                                <span className="error-message">
                                  {errors.processUserCTPSUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserProofOfAddressUploadFile"
                            >
                              Comp. de residência
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserProofOfAddressUploadFile &&
                                errors.processUserProofOfAddressUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserProofOfAddressUploadFile"
                                  id="processUserProofOfAddressUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserProofOfAddressUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputProofOfAddress}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputProofOfAddress.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserProofOfAddressUploadFile
                                    ? values.processUserProofOfAddressUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserProofOfAddressUploadFile &&
                              errors.processUserProofOfAddressUploadFile && (
                                <span className="error-message">
                                  {errors.processUserProofOfAddressUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserCNISUploadFile"
                            >
                              CNIS
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserCNISUploadFile &&
                                errors.processUserCNISUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserCNISUploadFile"
                                  id="processUserCNISUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserCNISUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputCNIS}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputCNIS.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserCNISUploadFile
                                    ? values.processUserCNISUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserCNISUploadFile &&
                              errors.processUserCNISUploadFile && (
                                <span className="error-message">
                                  {errors.processUserCNISUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserMedicalReportUploadFile"
                            >
                              Laudo Médico
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserMedicalReportUploadFile &&
                                errors.processUserMedicalReportUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserMedicalReportUploadFile"
                                  id="processUserMedicalReportUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserMedicalReportUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputMedicalReport}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputMedicalReport.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserMedicalReportUploadFile
                                    ? values.processUserMedicalReportUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserMedicalReportUploadFile &&
                              errors.processUserMedicalReportUploadFile && (
                                <span className="error-message">
                                  {errors.processUserMedicalReportUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserINSSRejectionUploadFile"
                            >
                              Indeferimento INSS
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserINSSRejectionUploadFile &&
                                errors.processUserINSSRejectionUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserINSSRejectionUploadFile"
                                  id="processUserINSSRejectionUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserINSSRejectionUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputINSSRejection}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputINSSRejection.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserINSSRejectionUploadFile
                                    ? values.processUserINSSRejectionUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserINSSRejectionUploadFile &&
                              errors.processUserINSSRejectionUploadFile && (
                                <span className="error-message">
                                  {errors.processUserINSSRejectionUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserOthersDocumentsUploadFile"
                            >
                              Outro documento
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserOthersDocumentsUploadFile &&
                                errors.processUserOthersDocumentsUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserOthersDocumentsUploadFile"
                                  id="processUserOthersDocumentsUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserOthersDocumentsUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputOthersDocuments}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputOthersDocuments.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserOthersDocumentsUploadFile
                                    ? values
                                        .processUserOthersDocumentsUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserOthersDocumentsUploadFile &&
                              errors.processUserOthersDocumentsUploadFile && (
                                <span className="error-message">
                                  {errors.processUserOthersDocumentsUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>
                      </>
                    ) : null}

                    {/* DOCS CIVIL */}
                    {selectedProcessArea === "civil" ||
                    selectedProcessArea === "empresarial" ||
                    selectedProcessArea === "tributario" ? (
                      <>
                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserRGUploadFile"
                            >
                              RG
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserRGUploadFile &&
                                errors.processUserRGUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserRGUploadFile"
                                  id="processUserRGUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserRGUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputRG}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputRG.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserRGUploadFile
                                    ? values.processUserRGUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserRGUploadFile &&
                              errors.processUserRGUploadFile && (
                                <span className="error-message">
                                  {errors.processUserRGUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserCPFUploadFile"
                            >
                              CPF
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserCPFUploadFile &&
                                errors.processUserCPFUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserCPFUploadFile"
                                  id="processUserCPFUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserCPFUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputCPF}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputCPF.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserCPFUploadFile
                                    ? values.processUserCPFUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserCPFUploadFile &&
                              errors.processUserCPFUploadFile && (
                                <span className="error-message">
                                  {errors.processUserCPFUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserCTPSUploadFile"
                            >
                              CTPS (Carteira de Trabalho)
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserCTPSUploadFile &&
                                errors.processUserCTPSUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserCTPSUploadFile"
                                  id="processUserCTPSUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserCTPSUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputCTPS}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputCTPS.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserCTPSUploadFile
                                    ? values.processUserCTPSUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserCTPSUploadFile &&
                              errors.processUserCTPSUploadFile && (
                                <span className="error-message">
                                  {errors.processUserCTPSUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserProofOfAddressUploadFile"
                            >
                              Comp. de residência
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserProofOfAddressUploadFile &&
                                errors.processUserProofOfAddressUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserProofOfAddressUploadFile"
                                  id="processUserProofOfAddressUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserProofOfAddressUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputProofOfAddress}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputProofOfAddress.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserProofOfAddressUploadFile
                                    ? values.processUserProofOfAddressUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserProofOfAddressUploadFile &&
                              errors.processUserProofOfAddressUploadFile && (
                                <span className="error-message">
                                  {errors.processUserProofOfAddressUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserTerminationTermUploadFile"
                            >
                              Termo de Rescisão
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserTerminationTermUploadFile &&
                                errors.processUserTerminationTermUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserTerminationTermUploadFile"
                                  id="processUserTerminationTermUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserTerminationTermUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputTerminationTerm}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputTerminationTerm.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserTerminationTermUploadFile
                                    ? values
                                        .processUserTerminationTermUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserTerminationTermUploadFile &&
                              errors.processUserTerminationTermUploadFile && (
                                <span className="error-message">
                                  {errors.processUserTerminationTermUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserOthersDocumentsUploadFile"
                            >
                              Outro documento
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserOthersDocumentsUploadFile &&
                                errors.processUserOthersDocumentsUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserOthersDocumentsUploadFile"
                                  id="processUserOthersDocumentsUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserOthersDocumentsUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputOthersDocuments}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputOthersDocuments.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserOthersDocumentsUploadFile
                                    ? values
                                        .processUserOthersDocumentsUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserOthersDocumentsUploadFile &&
                              errors.processUserOthersDocumentsUploadFile && (
                                <span className="error-message">
                                  {errors.processUserOthersDocumentsUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>
                      </>
                    ) : null}

                    {/* DOCS TRABALHO / CONSUMIDOR */}
                    {selectedProcessArea === "trabalho" ||
                    selectedProcessArea === "consumidor" ? (
                      <>
                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserRGUploadFile"
                            >
                              RG
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserRGUploadFile &&
                                errors.processUserRGUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserRGUploadFile"
                                  id="processUserRGUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserRGUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputRG}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputRG.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserRGUploadFile
                                    ? values.processUserRGUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserRGUploadFile &&
                              errors.processUserRGUploadFile && (
                                <span className="error-message">
                                  {errors.processUserRGUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserCPFUploadFile"
                            >
                              CPF
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserCPFUploadFile &&
                                errors.processUserCPFUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserCPFUploadFile"
                                  id="processUserCPFUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserCPFUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputCPF}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputCPF.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserCPFUploadFile
                                    ? values.processUserCPFUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserCPFUploadFile &&
                              errors.processUserCPFUploadFile && (
                                <span className="error-message">
                                  {errors.processUserCPFUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserCTPSUploadFile"
                            >
                              CTPS (Carteira de Trabalho)
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserCTPSUploadFile &&
                                errors.processUserCTPSUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserCTPSUploadFile"
                                  id="processUserCTPSUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserCTPSUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputCTPS}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() => fileInputCTPS.current.click()}
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserCTPSUploadFile
                                    ? values.processUserCTPSUploadFile.name ||
                                      "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserCTPSUploadFile &&
                              errors.processUserCTPSUploadFile && (
                                <span className="error-message">
                                  {errors.processUserCTPSUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserProofOfAddressUploadFile"
                            >
                              Comp. de residência
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserProofOfAddressUploadFile &&
                                errors.processUserProofOfAddressUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserProofOfAddressUploadFile"
                                  id="processUserProofOfAddressUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserProofOfAddressUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputProofOfAddress}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputProofOfAddress.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserProofOfAddressUploadFile
                                    ? values.processUserProofOfAddressUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserProofOfAddressUploadFile &&
                              errors.processUserProofOfAddressUploadFile && (
                                <span className="error-message">
                                  {errors.processUserProofOfAddressUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col sm={6} md={4}>
                          <div className="form-group">
                            <label
                              className="label"
                              htmlFor="processUserOthersDocumentsUploadFile"
                            >
                              Outro documento
                            </label>
                            <div
                              className={`file-input ${
                                touched.processUserOthersDocumentsUploadFile &&
                                errors.processUserOthersDocumentsUploadFile
                                  ? "-is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="content">
                                <input
                                  name="processUserOthersDocumentsUploadFile"
                                  id="processUserOthersDocumentsUploadFile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={event => {
                                    setFieldValue(
                                      "processUserOthersDocumentsUploadFile",
                                      event.currentTarget.files[0]
                                    )
                                  }}
                                  onBlur={handleBlur}
                                  ref={fileInputOthersDocuments}
                                />

                                <button
                                  className="btn-target"
                                  type="button"
                                  onClick={() =>
                                    fileInputOthersDocuments.current.click()
                                  }
                                >
                                  Selecione o arquivo
                                </button>

                                <small className="placeholder-label">
                                  <FileIcon className="icon" />
                                  {values.processUserOthersDocumentsUploadFile
                                    ? values
                                        .processUserOthersDocumentsUploadFile
                                        .name || "Error"
                                    : "Selecione um arquivo"}
                                </small>
                              </div>
                            </div>

                            {touched.processUserOthersDocumentsUploadFile &&
                              errors.processUserOthersDocumentsUploadFile && (
                                <span className="error-message">
                                  {errors.processUserOthersDocumentsUploadFile}
                                </span>
                              )}
                          </div>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                </fieldset>

                <div className="form-group -right">
                  <Button
                    type="submit"
                    theme="primary"
                    size="lg"
                    full={true}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Enviar processo
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </S.Form>
      )}
    </Formik>
  )
}

export default FormProcessIndication
