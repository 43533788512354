/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Row, Col } from "react-flexbox-grid"
import { StaticImage } from "gatsby-plugin-image"

import * as S from "./styled"

const AreasOfExpertise = props => {
  const { values, setFormStepState, setSelectedProcessArea } = props

  const handleFormStepState = e => {
    e.preventDefault()
    values.processAreasOfExpertise.selectedOption.value = e.target.dataset.value
    values.processAreasOfExpertise.selectedOption.label = e.target.dataset.label
    setSelectedProcessArea(e.target.dataset.value)
    setFormStepState(2)
  }

  return (
    <S.AreasOfExpertise>
      <Row>
        <Col className="card-wrapper" lg={6}>
          <S.Card className="-only-image">
            <StaticImage
              className="img"
              src="../../images/areas-of-expertise/prev.jpg"
              objectFit="contain"
              objectPosition="center"
              // placeholder="tracedSVG"
              alt="Direito previdenciário"
            />
            <a
              className="c-link -full"
              href="#"
              title="Direito previdenciário"
              onClick={handleFormStepState}
              data-value="previdenciario"
              data-label="Direito previdenciário"
            >
              <span className="sr-only">Direito previdenciário</span>
            </a>
          </S.Card>
        </Col>

        <Col className="card-wrapper" lg={6}>
          <S.Card className="-only-image">
            <StaticImage
              className="img"
              src="../../images/areas-of-expertise/civil.jpg"
              objectFit="contain"
              objectPosition="center"
              // placeholder="tracedSVG"
              alt="Direito civil"
            />
            <a
              className="c-link -full"
              href="#"
              title="Direito civil"
              onClick={handleFormStepState}
              data-value="civil"
              data-label="Direito civil"
            >
              <span className="sr-only">Direito civil</span>
            </a>
          </S.Card>
        </Col>

        <Col className="card-wrapper" lg={6}>
          <S.Card className="-only-image">
            <StaticImage
              className="img"
              src="../../images/areas-of-expertise/trabalho.jpg"
              objectFit="contain"
              objectPosition="center"
              // placeholder="tracedSVG"
              alt="Direito do trabalho"
            />
            <a
              className="c-link -full"
              href="#"
              title="Direito do trabalho"
              onClick={handleFormStepState}
              data-value="trabalho"
              data-label="Direito do trabalho"
            >
              <span className="sr-only">Direito do trabalho</span>
            </a>
          </S.Card>
        </Col>

        <Col className="card-wrapper" lg={6}>
          <S.Card className="-only-image">
            <StaticImage
              className="img"
              src="../../images/areas-of-expertise/consu.jpg"
              objectFit="contain"
              objectPosition="center"
              // placeholder="tracedSVG"
              alt="Direito do consumidor"
            />
            <a
              className="c-link -full"
              href="#"
              title="Direito do consumidor"
              onClick={handleFormStepState}
              data-value="consumidor"
              data-label="Direito do consumidor"
            >
              <span className="sr-only">Direito do consumidor</span>
            </a>
          </S.Card>
        </Col>
        <Col className="card-wrapper" lg={6}>
          <S.Card className="-only-image">
            <StaticImage
              className="img"
              src="../../images/areas-of-expertise/empresarial.jpg"
              objectFit="contain"
              objectPosition="center"
              // placeholder="tracedSVG"
              alt="Direito Empresarial"
            />
            <a
              className="c-link -full"
              href="#"
              title="Direito Empresarial"
              onClick={handleFormStepState}
              data-value="empresarial"
              data-label="Direito Empresarial"
            >
              <span className="sr-only">Direito Empresarial</span>
            </a>
          </S.Card>
        </Col>
        <Col className="card-wrapper" lg={6}>
          <S.Card className="-only-image">
            <StaticImage
              className="img"
              src="../../images/areas-of-expertise/tributario.jpg"
              objectFit="contain"
              objectPosition="center"
              // placeholder="tracedSVG"
              alt="Direito Tributario"
            />
            <a
              className="c-link -full"
              href="#"
              title="Direito Tributario"
              onClick={handleFormStepState}
              data-value="tributario"
              data-label="Direito Tributario"
            >
              <span className="sr-only">Direito Tributario</span>
            </a>
          </S.Card>
        </Col>
      </Row>
    </S.AreasOfExpertise>
  )
}

export default AreasOfExpertise
