import * as React from "react"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import SectionHero from "../SectionHero"
import { Paragraph } from "../../Text"
import FormProcessIndication from "../../Form/ProcessIndication"
import { Button } from "../../Button"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import * as S from "./styled"

const SectionProcesses = () => (
  <S.Section>
    <SectionHeader>
      <SectionHero>
        <Container>
          <SectionTitle as="h1" medium noMargin colorInverse>
            Cadastre um processo
          </SectionTitle>
        </Container>
      </SectionHero>
    </SectionHeader>

    <SectionContent className="s-content">
      <Container>
        <div className="box-header">
          <Paragraph className="paragraph" darken>
            Você faz parte de alguma ong e/ou instituição que está necessitando
            de apoio juridico? Você, algum familiar ou amigo está precisando de
            apoio juridico?
          </Paragraph>

          <Paragraph className="paragraph" darken>
            Conte-nos um pouco sobre o seu caso e entraremos em contato.
          </Paragraph>

          <Paragraph className="paragraph" darken>
            * Por favor preencha os campos com os dados do responsavel pelo
            processo.
          </Paragraph>

          <Paragraph className="paragraph" darken>
            Se desejar, você pode falar agora com um advogado.
          </Paragraph>
          <Button
            className="btn"
            as={AniLink}
            theme="secondary"
            to="/contato"
            cover
            direction="bottom"
            bg="#EAEAEA"
            duration={0.8}
            title="Agendar uma Consulta"
          >
            Agendar uma Consulta
          </Button>
        </div>
        <div className="box-content">
          <FormProcessIndication />
        </div>
      </Container>
    </SectionContent>
  </S.Section>
)

export default SectionProcesses
