import styled from "styled-components"

import { Section as SectionComponent } from "../"
import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Section = styled(SectionComponent)`
  padding: 0 0 100px !important;
  @media ${breakpoints.lessThan(sizes.tablet)} {
    padding: 0 0 60px;
  }

  .s-content {
    margin-top: 50px;

    .box-header {
      max-width: 550px;
      margin-bottom: 50px;
      @media ${breakpoints.lessThan(sizes.tablet)} {
        margin-bottom: 40px;
      }

      .paragraph {
        line-height: 1.5;

        &:not(:last-of-type) {
          margin-bottom: 25px;
        }
      }
    }

    .box-content {
      /* margin: 0 auto; */
      /* max-width: 550px; */
    }
  }
`
